/* Scrollbar */

/* width */
::-webkit-scrollbar {
    width: 5px;
    background: white;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-lavender;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color-lavender;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Scrollbar */
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-jsb {
    justify-content: space-between;
}

.flex-jsa {
    justify-content: space-around;
}

.flex-js {
    justify-content: flex-start;
}

.flex-je {
    justify-content: flex-end;
}

.flex-jc {
    justify-content: center;
}

.flex-as {
    align-items: flex-start;
}

.flex-ae {
    align-items: flex-end;
}

.flex-ac {
    align-items: center;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.d-flex {
    display: flex;
}

.position-relative {
    position: relative;
}

.d-block {
    display: block;
}

.d-none {
    display: none;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pr-20 {
    padding-right: 20px !important;
}

//.flex-2 {
//    flex: 2;
//}
//
//.flex-3 {
//    flex: 3;
//}
//
//.flex-4 {
//    flex: 4;
//}
//
//.flex-5 {
//    flex: 5;
//}
//
//.flex-6 {
//    flex: 6;
//}

.f-12 {
    font-size: 12px;
}

.f-10 {
    font-size: 10px;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.p-15 {
    padding: 15px;
}

.p-30 {
    padding: 30px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-0 {
    margin-top: 0;
}


.mb-0 {
    margin-bottom: 0;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-4 {
    margin-right: 24px;
}

.ml-2 {
    margin-left: 8px;
}

.ml-20 {
    margin-left: 20px !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mr-20 {
    margin-right: 20px;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-80 {
    width: 80%;
}

.w-65 {
    width: 65%;
}

.w-49 {
    width: 49%;
}

.text-center {
    text-align: center;
}

.text-end {
    text-align: right;
}

.react-toggle--checked {
    .react-toggle-track {
        background-color: #006eb8 !important;
    }
}

.react-responsive-modal-closeButton {
    display: none !important;
}

.react-responsive-modal-overlay {
    z-index: 1300 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.react-responsive-modal-modal {
    background: #fff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    padding: 15px 30px;
    min-width: 488px;
    min-height: 244px;
    max-width: 1000px !important;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.hidden {
    display: none;
}

.clickable {
    cursor: pointer;
}

/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    font-size: 14px;
    color: $color-gray-3;
    text-align: center;
    padding: $spacing-half $spacing;
    background: #fff;
    box-shadow: $box-shadow-light;
    border-radius: $border-radius-button;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: 150%;
    right: -120px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.error {
    color: $color-red;
}

.overflow-unset {
    overflow: unset;
}

.fade-in {
    animation: fadeIn 1.5s;
}

.fade-out {
    animation: fadeOut 1.5s;
    display: none;
}

@keyframes fadeIn {
    from {
        display: none;
        opacity: 0;
    }

    to {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        display: block;
        opacity: 1;
    }

    to {
        display: none;
        opacity: 0;
    }
}

