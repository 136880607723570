@import 'styles/_variables';
@import 'styles/_mixins';

.AppButton {
    $root: &;

    $types: (
        primary: (
            bg: $color-red-blue-gradient,
            color: white,
            bg-hover: $color-red-blue-gradient,
            color-hover: white,
            bg-active: $color-red-blue-gradient,
        ),
        secondary: (
            bg: white,
            color: transparent,
            bg-hover: white,
            color-hover: transparent,
            bg-active: white,
            content-props: (
                background: $color-red-blue-gradient,
                -webkit-background-clip: text,
                text-fill-color: transparent,
                display: inline-block
            ),
        ),
        dark-blue: (
            bg: $color-dark-blue,
            color: white,
            bg-hover: $color-dark-blue,
            color-hover: white,
            bg-active: $color-dark-blue,
        ),
        lavender: (
            bg: $color-lavender,
            color: black,
            bg-hover: $color-lavender,
            color-hover: black,
            bg-active: black,
        ),
        lavender-blue: (
            bg: $color-lavender,
            color: $color-dark-blue,
            bg-hover: $color-lavender,
            color-hover: $color-dark-blue,
            bg-active: $color-dark-blue,
        ),
        outline-white: (
            bg: transparent,
            color: white,
            bg-hover: transparent,
            color-hover: white,
            bg-active: transparent,
            border: 1px solid white,
        ),
        outline-blue: (
            bg: transparent,
            color: black,
            bg-hover: transparent,
            color-hover: black,
            bg-active: transparent,
            border: 1px solid $color-dark-blue,
        ),
        outline-dark-blue: (
            bg: transparent,
            color: $color-dark-blue,
            bg-hover: transparent,
            color-hover: $color-dark-blue,
            bg-active: transparent,
            border: 1px solid $color-dark-blue,
        ),
        outline-blue-gray: (
            bg: transparent,
            color: $color-lightgray,
            bg-hover: transparent,
            color-hover: $color-lightgray,
            bg-active: transparent,
            border: 1px solid $color-dark-blue,
        ),
        outline-electric-blue: (
            bg: transparent,
            color: $color-lightgray,
            bg-hover: transparent,
            color-hover: $color-lightgray,
            bg-active: transparent,
            border: 1px solid $color-electric-blue,
        ),
        outline-gray: (
            bg: transparent,
            color: $color-lightgray,
            bg-hover: transparent,
            color-hover: $color-lightgray,
            bg-active: transparent,
            border: 1px solid $color-lightgray,
        ),
        outline-light-gray: (
            bg: transparent,
            color: #AAA,
            bg-hover: transparent,
            color-hover: #AAA,
            bg-active: transparent,
            border: 1px solid #AAA,
        ),
        social-google: (
            bg: $color-blue-google,
            color: white,
            bg-hover: $color-blue-google,
            color-hover: white,
            bg-active: $color-blue-google,
            border-radius: 10px,
        ),
        social-facebook: (
            bg: $color-blue-facebook,
            color: white,
            bg-hover: $color-blue-facebook,
            color-hover: white,
            bg-active: $color-blue-facebook,
            border-radius: 10px,
        ),
        social-metamask: (
            bg: $color-gray-disable, //$color-yellow-metamask,
            color: white,
            bg-hover: $color-gray-disable, //$color-yellow-metamask,
            color-hover: white,
            bg-active: $color-gray-disable, //$color-yellow-metamask,
            border-radius: 10px,
        ),
        alice-gray: (
            bg: $color-alice-blue,
            color: $color-lightgray,
            bg-hover: $color-alice-blue,
            color-hover: $color-lightgray,
            bg-active: $color-alice-blue,
            bg-selected: $color-dark-blue,
            color-selected: white,
            border-radius: 5px
        ),
        alice-blue: (
            bg: $color-alice-blue,
            color: $color-dark-blue,
            bg-hover: $color-alice-blue,
            color-hover: $color-dark-blue,
            bg-active: $color-alice-blue,
            bg-selected: $color-dark-blue,
            color-selected: white,
            border-radius: 5px
        )
    );

    $shrink-duration: 0.5s;
    $hide-duration: 0.2s;
    $scale-hide-duration: 0.2s;

    display: inline-block;
    position: relative;
    border-radius: $border-radius-button;
    font-size: 18px;
    text-align: center;

    @include screen-xlg {
        font-size: 14px;
    }

    &__placeholder {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        box-sizing: border-box;
        padding: $spacing/4 $spacing;
        opacity: 0;
        cursor: default;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;

        #{$root}--loading & {
            white-space: nowrap;
        }

        #{$root}--noPadding & {
            padding: 0;
        }
    }

    &__button {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-49.99%);
        text-align: inherit;
        height: 100%;
        width: 100%;
        border: none;
        border-radius: inherit;
        box-sizing: border-box;
        padding: $spacing/4 $spacing;
        outline: none;
        cursor: pointer;
        transition: background 0.25s, color 0.25s;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;

        #{$root}--no-shrink & {
            animation-name: none !important;
        }

        #{$root}--noPadding & {
            padding: 0;
        }

        &-content {
            display: inline-flex;
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        &-loading {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 100%;
        }
    }

    &--round {
        border-radius: 50px;
    }

    &--ignoreClick {
        cursor: default;

        * {
            cursor: default;
        }
    }

    &--disabled {
        opacity: 0.6;
        cursor: no-drop;

        * {
            cursor: no-drop;
        }
    }

    &--loading {
        cursor: wait;

        * {
            cursor: wait;
        }
    }

    &--loading &__button {
        animation-name: loading;
        animation-duration: $shrink-duration;
        animation-timing-function: cubic-bezier(0.81, 0.72, 0.53, 0.31);
        animation-delay: $scale-hide-duration;
        animation-direction: normal;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-play-state: running;
        white-space: nowrap;

        &-content {
            animation-name: scale-hide;
            animation-duration: $scale-hide-duration;
            animation-timing-function: cubic-bezier(0.81, 0.72, 0.53, 0.31);
            animation-delay: 0s;
            animation-direction: normal;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-play-state: running;
        }

        &-loading {
            animation-name: show;
            animation-duration: $hide-duration;
            animation-timing-function: cubic-bezier(0.81, 0.72, 0.53, 0.31);
            animation-delay: $shrink-duration + $scale-hide-duration;
            animation-direction: normal;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-play-state: running;
        }
    }

    &--not-loading &__button {
        animation-name: not-loading;
        animation-duration: $shrink-duration;
        animation-timing-function: cubic-bezier(0.81, 0.72, 0.53, 0.31);
        animation-delay: 0s;
        animation-direction: normal;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-play-state: running;
        white-space: nowrap;

        &-content {
            opacity: 0;
            animation-name: scale-show;
            animation-duration: $scale-hide-duration;
            animation-timing-function: cubic-bezier(0.81, 0.72, 0.53, 0.31);
            animation-delay: $shrink-duration + $hide-duration;
            animation-direction: normal;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-play-state: running;
        }
    }

    @each $label, $type in $types {
        &--#{$label} {
            border-radius: map-get($type, border-radius);

            #{$root}__button {
                background: map-get($type, bg);
                color: map-get($type, color);

                &-loading {
                    &-spinner {
                        stroke: map-get($type, color);
                    }
                }

                &-content {
                    @each $prop, $value in map-get($type, content-props) {
                        #{$prop}: $value;
                    }
                }
            }

            &#{$root}--active #{$root}__button:active {
                background: map-get($type, bg-active);
                box-shadow: $box-shadow-light;
            }

            &#{$root}--hover #{$root}__button:hover {
                background: map-get($type, bg-hover);
                color: map-get($type, color-hover);

                #{$root}__button-loading {
                    &-spinner {
                        stroke: map-get($type, color-hover);
                    }
                }
            }

            &#{$root}--hovered #{$root}__button {
                background: map-get($type, bg-hover);
                color: map-get($type, color-hover);
            }

            &#{$root}--border {
                #{$root}__placeholder {
                    border: map-get($type, border);
                }

                #{$root}__button {
                    border: map-get($type, border);
                }
            }

            &#{$root}--selected {
                #{$root}__placeholder {
                    border: map-get($type, border-selected);
                }

                #{$root}__button {
                    border: map-get($type, border-selected);
                    background: map-get($type, bg-selected) !important;
                    color: map-get($type, color-selected) !important;
                }
            }
        }
    }

    @keyframes scale-hide {
        0% {
            opacity: 1;
        }

        95% {
            opacity: 0;
            transform: scale(1);
        }

        100% {
            opacity: 1;
            transform: scale(0);
        }
    }

    @keyframes scale-show {
        0% {
            opacity: 0;
            transform: scale(1);
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes hide {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }


    @mixin loading-keyframe($base, $expanded) {
        @keyframes loading {
            0% {
                width: 100%;
            }

            70% {
                width: $base;
            }

            85% {
                width: $expanded;
            }

            100% {
                width: $base;
            }
        }
    }

    @mixin not-loading-keyframe($base, $shrunk) {
        @keyframes not-loading {
            0% {
                width: $base;
            }

            15% {
                width: $shrunk;
            }

            30% {
                width: $base;
            }

            100% {
                width: 100%;
            }
        }
    }

    @include loading-keyframe(60px, 70px);
    @include not-loading-keyframe(60px, 50px);

    @include screen-xlg {
        @include loading-keyframe(50px, 60px);
        @include not-loading-keyframe(50px, 40px);
    }
}
