@import "styles/_variables";
@import "styles/_mixins";

.Blue-Header {
    position: absolute;

    &__back,
    &__menu-icon {
        margin-right: 31.5px;
        cursor: pointer;
    }

    &__back-admin {
        cursor: pointer;
        position: absolute;
        top: 50px;
        left: 30px;
    }

    &__close-admin {
        cursor: pointer;
        position: absolute;
        top: 50px;
        right: 30px;
    }

    &__classes {
        position: absolute;
        top: 22px;
        left: 110px;
        width: 300px;
        height: 142px;
        background: #fff;
        box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 20px;

        &-times {
            right: 15px;
            top: 15px;
            position: absolute;
            cursor: pointer;
        }

        &-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #006eb8;
            margin: 15px;
        }

        &-icon {
            margin-right: 20px;
        }
    }

    &__company {
        padding: 14px 20px 14px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        cursor: pointer;

        &-avatar {
            width: 35px;
            height: 35px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 15px;

            @include screen-xlg() {
                width: 24px;
                height: 24px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-name {
            font-size: 15px;

            @include screen-xlg() {
                font-size: 12px;
            }
        }

        &-item {
            padding: 10px 0 10px 75px;
            background: #efefef;
            cursor: pointer;
            margin: 10px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            color: #000;

            @include screen-xlg() {
                font-size: 12px;
            }
        }

        &-chevron {
            width: 18px !important;
            height: 18px !important;
            color: #999;

            @include screen-xlg() {
                width: 13px !important;
                height: 13px !important;
            }
        }
    }

    #side-nav-outer-container {
        position: absolute;
    }

    &__menu {
        width: 352px;
        background: #fff;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 10px 0 0 10px;
        overflow: auto;
        height: 100%;

        &-back {
            position: absolute;
            left: 21px;
            cursor: pointer;
        }

        &-title {
            height: 40px;
            background: $color-grey1;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            text-align: center;
            color: #fff;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            @include screen-xlg() {
                height: 29px;
                font-size: 12px;
                font-weight: 500;
            }
        }

        &-badge {
            min-width: 35px;
            min-height: 35px;
            max-height: 35px;
            background: $color-red2;
            border-radius: 50%;
            font-weight: 600;
            font-size: 20px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            @include screen-xlg() {
                min-width: 24px;
                min-height: 24px;
                height: 24px;
                font-size: 14px;
            }
        }

        &-more {
            position: absolute;
            font-weight: 300;
            font-size: 30px;
            line-height: 30px;
            color: #fff;
            top: 10px;
            right: 20px;
            cursor: pointer;

            @include screen-xlg() {
                line-height: 15px;
                font-size: 20px;
                top: 8px;
            }
        }

        &-item {
            margin: 26px 28px;
            display: flex;
            cursor: pointer;

            @include screen-xlg() {
                margin: 20px 25px;
            }

            p {
                margin-left: 15px;
                font-weight: 600;
                font-size: 15px;
                //line-height: 19px;
                padding-bottom: 16px;
                margin-top: 0;
                border-bottom: 0.5px solid #58595b;
                width: 100%;
                margin-bottom: 0;

                @include screen-xlg() {
                    font-size: 12px;
                }
            }

            svg {
                min-width: 35px;

                @include screen-xlg() {
                    min-width: 24px;
                }
            }
        }
    }

    &__title {
        font-weight: 500;
        font-size: 40px;
        color: #fff;
    }

    &__avatar {
        border-radius: 50%;
        overflow: hidden;
        width: 56px;
        height: 56px;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &__badge {
        width: 19px;
        height: 19px;
        background: $color-red2;
        position: absolute;
        top: 0;
        right: 0;
        font-weight: 600;
        display: flex;
        align-items: center;
        text-align: center;
        color: #fff;
        font-size: 13px;
        justify-content: center;
        border-radius: 50%;
    }
}

.bm-menu-wrap {
    @include header-height("top");
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
    display: none;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3) !important;
    left: 0;

    @include header-height("top");

    @include screen-lg {
        top: 65px;
    }

    @include screen-xlg {
        @include header-height("top");
    }
}

.Notifications-Menu {
    outline: none;
    background: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
    max-height: calc(100vh - 96px);
    overflow-y: scroll;
    position: relative;

    &__tabs {
        display: flex;
        width: 300px;
        background: #efefef;
        border-radius: 7px;
        height: 35px;
        margin: 15px auto 30px;
        align-items: center;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;

        @include screen-xlg() {
            font-size: 12px;
        }
    }

    &__tab {
        cursor: pointer;
        width: 150px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &.-is-active {
            background: #999;
            border-radius: 7px;
            width: 150px;
            height: 100%;
            color: #fff;
        }
    }
}

.Notifications {
    &__item {
        border: 1px solid #999;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 16px;

        &-content {
            padding: 10px;
        }

        &-info {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #828282;

            &-msg {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #333;
                margin-top: 0;
            }
        }

        &-user {
            $notificationUser: &;

            border-radius: 50%;
            width: 32px;
            height: 32px;
            overflow: hidden;

            @include screen-xlg() {
                width: 28px;
                height: 28px;
            }

            img {
                &#{ $notificationUser }--circle-icon {
                    border-radius: 50%;
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                }

                width: 100%;
                height: 100%;
                padding: 5px;
            }

            &.-second {
                margin-left: -5px;
            }
        }

        &-time {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #58595b;
            margin-bottom: 13px;

            svg {
                margin-left: 10px;
            }
        }

        &-header {
            height: 44px;
            background: #efefef;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 11px;

            @include screen-xlg() {
                height: 31px;
            }
        }

        &-title {
            font-weight: 600;
            font-size: 15px;
            line-height: 15px;
            color: #58595b;
            margin-left: 5px;

            @include screen-xlg() {
                font-size: 12px;
            }
        }

        &-hr {
            min-width: 130px;
            font-weight: 600;
            font-size: 13px;
            line-height: 15px;
            color: #828282;
        }

        &-dot {
            width: 8px;
            height: 8px;
            background: #eb5757;
            border-radius: 50%;
            display: inline-block;
            margin-left: 8px;
        }
    }

    &__list {
        padding: 0 26px;
        padding-bottom: 20px;
    }
}

.Preferences {
    &__info {
        font-weight: 600;
        font-size: 11px;
        line-height: 14px;
        color: #828282;
        margin-top: 0;
        position: relative;
        top: -20px;
        padding-left: 30px;
    }

    &__toggle {
        position: relative;
        right: 23px;

        .react-toggle-thumb {
            border: none;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        background: #efefef;
        height: 50px;
        margin-top: 15px;
        padding: 0 20px;
    }

    &__icon {
        margin-left: 10px;
    }

    &__preference {
        display: flex;
        justify-content: space-between;
        height: 55px;
        padding: 0 20px;
        border-bottom: 1px solid #e0e0e0;
        align-items: center;

        @include screen-xlg() {
            height: 50px;
        }
        &-title {
            font-size: 13px;
            font-weight: 500;
        }

        &-description {
            font-size: 12px;
        }

    }
}

.Social {
    &__info {
        text-align: center;
        font-weight: 600;
        font-size: 11px;
        line-height: 14px;
        color: #828282;
        margin-bottom: 20px;
    }

    &__modal {
        &-title {
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            text-align: center;
            color: #58595b;
            margin-top: 20px;
        }

        &-subtitle {
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            text-align: center;
            color: #58595b;
        }

        &-cancel-btn {
            border: 1px solid #fff;
            background-color: #fff;
            width: 125px;
            height: 50px;
            margin: 15px;
            border-radius: 4px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #58595b;
            cursor: pointer;

            @include screen-xlg() {
                width: 100px;
                height: 35px;
                font-size: 14px;
            }

            &.-ok {
                border: 1px solid #bdbdbd;
                border-radius: 3px;
                width: 200px;
                margin-top: 80px;
            }

            &.-has-border {
                border: 1px solid #bdbdbd;
                box-sizing: border-box;
                border-radius: 3px;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                color: #bdbdbd;
                width: 200px;
            }

            &.-btn-active {
                background: #006eb8;
                color: #fff;
                border: none;
            }
        }

        &-continue-btn {
            border: 1px solid #999;
            background-color: #999;
            width: 125px;
            height: 50px;
            margin: 15px;
            border-radius: 4px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #fff;
            cursor: pointer;

            @include screen-xlg() {
                width: 100px;
                height: 35px;
                font-size: 14px;
            }
        }

        &-ok-btn {
            border: 1px solid #006eb8;
            background-color: #006eb8;
            width: 125px;
            height: 50px;
            margin: 15px;
            border-radius: 4px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }

        &-go-btn {
            border: none;
            background: linear-gradient(
                224.89deg,
                #eb1c6d 14.61%,
                #006eb8 85.17%
            );
            width: 125px;
            height: 50px;
            margin: 15px;
            border-radius: 4px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
    }

    &__list {
        padding: 0 26px;
        padding-bottom: 0;
    }

    &__item {
        margin: 16px 28px;
        display: flex;
        align-items: center;

        &-icon {
            padding-bottom: 16px;
            width: 30px;

            @include screen-xlg() {
                width: 20px;
                padding-bottom: 18px;
            }
        }

        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 0.5px solid #58595b;
            width: 100%;
            margin-left: 20px;
            min-height: 30px;
            margin-top: 5px;
            padding-bottom: 16px;

            @include screen-xlg() {
                margin-top: 0;
                min-height: 20px;
                max-height: 35px;

                p {
                    font-size: 12px !important;
                }
            }
        }

        &-link {
            font-weight: 600;
            font-size: 13px;
            margin-top: 0;
            margin-bottom: 0;
            color: #006eb8;
            cursor: pointer;
            text-decoration: underline;
            padding-right: 5px !important;

            span {
                color: #006eb8;
                font-weight: 600;
                font-size: 13px;
            }

            &.AppButton--social-facebook .AppButton__button {
                color: #006eb8;
                background: transparent;
            }
        }

        p {
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            margin-top: 0;
            margin-bottom: 0;
            color: #000;
        }

        &-subtext {
            font-weight: 600;
            font-size: 12px !important;
            line-height: 15px !important;
            padding-bottom: 10px;
            margin-top: 0;
            margin-bottom: 0;
            color: #999 !important;
        }
    }
}

.Faq {
    &__content {
        max-height: calc(100vh - 206px);
        overflow-y: scroll;
        padding: 15px;
    }

    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 13px;
        border-bottom: 1px solid #e0e0e0;
        cursor: pointer;

        &-chevron {
            width: 18px !important;
            height: 18px !important;
            color: #999;
        }

        &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 15px !important;
            line-height: 19px;
            color: #58595b;
            margin: 0;

            @include screen-xlg() {
                font-size: 12px;
            }
        }

        &-dropdown {
            background: #efefef;
            padding: 10px;
        }

        &-dropdown-text {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #333;
        }

        &-dropdown-subtext {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #828282;

            a {
                color: #006eb8;
                text-decoration: underline;
            }
        }
    }
}

.Support {
    &__content {
        height: calc(100vh - 206px);
        overflow-y: scroll;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &__modal {
        &--wide {
            width: 100%;
            max-width: 1300px !important;
            max-height: calc(100vh - 200px);
            overflow-x: hidden;
        }

        &--semi-wide {
            width: 80vw;
        }

        &-invite-item {
            background: #fff;
            cursor: pointer;
            width: 350px;
            height: 75px;
            box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
            border-radius: 8px;
            margin: 0 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 19px 25px;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            color: #58595b;
        }

        &-avatar {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fbfafa;
            border: 1px solid #eee;

            /* for the icon */
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #006eb8;

            &.-is-25 {
                left: 25%;
            }

            &.-is-50 {
                left: 50%;
            }

            &.-is-75 {
                left: 75%;
            }

            &-image {
                width: 80px;
                margin: auto;
            }
        }

        &-delete {
            text-align: center;

            &-image {
                display: flex;
                justify-content: center;
            }

            img {
                width: 80px;
                height: 80px;
                margin: 10px auto;
                border-radius: 8px;

                @include screen-xlg() {
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                }
            }
        }

        &-header {
            background: #006eb8;
            position: absolute;
            top: 0;
            left: 0;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            width: 100%;
            height: 100px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff !important;

            h2 {
                color: #fff;
            }
        }

        &-progress {
            border: 2px solid #ededee;
            position: relative;
            background: #ededee;

            &-filled {
                border: 2px solid #006eb8;
                display: block;
                position: absolute;
                top: -2px;
                background: #006eb8;

                &.-is-50 {
                    width: 50%;
                }

                &.-is-95 {
                    width: 95%;
                }

                &.-is-25 {
                    width: 25%;
                }

                &.-is-75 {
                    width: 75%;
                }
            }

            &-knob {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: block;
                position: absolute;
                top: -10px;
                background: #006eb8;

                &.-is-50 {
                    left: 50%;
                }

                &.-is-95 {
                    left: 95%;
                }

                &.-is-25 {
                    left: 25%;
                }

                &.-is-75 {
                    left: 75%;
                }
            }
        }

        &-divider {
            display: flex;
            width: 100%;
            height: 2px;
            background: #e0e0e0;
            margin-bottom: 50px;
        }

        &-title {
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            text-align: center;
            color: #58595b;

            @include screen-xlg() {
                font-size: 20px;
                //font-weight: 500;
            }

            &.-search {
                margin-bottom: 10px;
            }
        }

        &-border {
            height: 2px;
            width: 100%;
            background-color: #e0e0e0;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &-admin {
            &-text {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                color: #58595b;
                margin-bottom: 0;
                margin-top: 0;
            }

            &-subtext {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #828282;
            }
        }

        &-item {
            height: 215px;
            width: 155px;
            background-color: #fff;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.12);
            margin: 30px;
            border-radius: 20px;
            cursor: pointer;
            position: relative;

            img {
                width: 100px;
                height: 100px;
            }

            p {
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                color: #58595b;
            }

            &.-yellow {
                background-color: rgba(255, 236, 50, 0.5);
                border-radius: 20px;
            }

            &.-blue {
                background-color: rgba(209, 236, 255, 0.5);
                border-radius: 20px;
            }

            &.-gray {
                background-color: rgba(236, 238, 240, 0.5);
                border-radius: 20px;
            }

            &.-pink {
                background-color: rgba(251, 210, 226, 0.5);
                border-radius: 20px;
            }

            &.-green {
                background-color: rgba(191, 255, 218, 0.5);
                border-radius: 20px;
            }

            &-bg {
                width: 155px;
                height: 200px;
                background: #fff;
                border-radius: 20px;
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }

        &-new {
            &-item {
                width: 370px;
                height: 70px;
                background-color: #fff;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                margin: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            &-icon {
                width: 50px;
                height: 50px;
                margin: 0 30px;
            }

            &-title {
                font-weight: 600;
                font-size: 21px;
                line-height: 27px;
                text-align: center;
                color: #58595b;
                margin-bottom: 0;
            }

            &-subtitle {
                font-weight: 600;
                font-size: 15px;
                line-height: 19px;
                text-align: center;
                color: #58595b;
                margin-top: 0;
            }
        }

        .-no-bg {
            width: 155px;
            height: 215px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: relative;
            border-radius: 20px;
            margin: 30px;

            .Support__modal-item {
                margin: 0;
                height: 200px !important;
            }
        }

        .-has-bg {
            width: 155px;
            height: 215px;
            background: #006eb8;
            display: flex;
            align-items: flex-start;
            border-radius: 20px;
            justify-content: center;
            position: relative;
            margin: 30px;

            .Support__modal-item {
                margin: 0;
                height: 200px;
            }
        }

        &-subtitle {
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            text-align: center;
            color: #58595b;
            padding-bottom: 15px;

            @include screen-xlg() {
                width: 90%;
                margin: auto;
                font-size: 14px;
                font-weight: 500;
            }

            &.-search {
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                color: #58595b;
                margin-bottom: 40px;
            }
        }

        &-subtitle2 {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #58595b;
        }

        //&-avatar {
        //    display: flex;
        //    flex-direction: column;
        //    align-items: center;
        //}

        &-avatar-icon {
            width: 60px;
            height: 60px;
        }

        &-avatar-title {
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            text-align: center;
            color: #58595b;
            margin-bottom: 0;
            margin-top: 5px;

            @include screen-xlg() {
                font-size: 16px;
            }
        }

        &-avatar-subtitle {
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            text-align: center;
            color: #58595b;
            margin-top: 0;
        }

        &-cancel-btn {
            border: 1px solid #fff;
            background-color: #fff;
            width: 125px;
            height: 50px;
            margin: 15px;
            border-radius: 4px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #58595b;
            cursor: pointer;
        }

        &-delete-btn {
            border: 1px solid #999;
            background-color: #999;
            width: 125px;
            height: 50px;
            margin: 15px;
            border-radius: 4px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
    }

    &__header {
        &-text {
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 14px;
            color: #828282;
            margin-bottom: 0;
        }

        &-icon {
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 28px;
            color: #58595b;
            margin-right: 15px;
        }

        &-link {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            text-decoration-line: underline;
            color: #006eb8;
            cursor: pointer;
        }
    }

    &__footer {
        &-icon {
            width: 50px;
            height: 50px;
        }

        &-text {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #333;
            text-align: center;
        }

        &-button {
            background: #006eb8;
            border-radius: 5px;
            width: 220px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;
        }

        &-link {
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            text-decoration-line: underline;
            color: #006eb8;
            cursor: pointer;
            text-align: center;
        }
    }
}

.Description {
    &__content {
        //max-height: calc(100vh - 206px);
        overflow-y: scroll;
        padding: 25px 15px 25px 25px;

        @include screen-xlg() {
            padding: 15px 25px;
        }

        &-avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 15px;
            margin-top: 38px;

            @include screen-xlg() {
                width: 42px;
                height: 42px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-avatar2 {
            width: 63px;
            height: 60px;
            margin-right: 15px;
            margin-top: 58px;
            position: relative;

            @include screen-xlg() {
                width: 45px;
                height: 42px;
            }

            &-image {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                object-fit: cover;

                @include screen-xlg() {
                    width: 42px;
                    height: 42px;
                }
            }

            //img {
            //    width: 100%;
            //    height: 100%;
            //    border-radius: 50%;
            //    object-fit: cover;
            //}

            &-icon-upload {
                width: 40px !important;
                height: 40px !important;
                position: absolute;
                bottom: -10px;
                right: -10px;
                cursor: pointer;

                @include screen-xlg() {
                    width: 27px !important;
                    height: 27px !important;
                }
            }
        }
    }

    &__company {
        &-title {
            width: 400px;
            font-weight: 800;
            font-size: 25px;
            line-height: 32px;
            color: #58595b;
            margin-top: 0;

            @include screen-xlg() {
                font-size: 18px;
                font-weight: 700;
            }
        }

        &-subtitle {
            //font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: #58595b;
            max-width: 605px;
            word-break: break-all;

            @include screen-xlg() {
                font-size: 12px;
            }
        }
    }

    &__user {
        &-title {
            font-weight: 800;
            font-size: 25px;
            line-height: 32px;
            color: #58595b;
            margin-top: 20px;
            margin-bottom: 15px;

            @include screen-xlg() {
                font-size: 18px;
                font-weight: 700;
            }
        }

        &-subtitle {
            font-size: 16px;
            line-height: 20px;
            color: #58595b;
            min-height: 132px;
            background: #efefef;
            border-radius: 7px;
            padding: 15px;
            outline: none !important;
            border: none;
            margin-top: 10px;
            resize: none;

            @include screen-xlg() {
                min-height: 93px;
                font-size: 12px;
            }
        }

        &-text {
            height: 40px;
            font-size: 16px;
            //line-height: 20px;
            color: #58595b;
            max-width: 115px;
            background: #efefef;
            border-radius: 7px;
            padding: 10px;
            margin: 0;
            margin-right: 15px;
            outline: none !important;
            border: none;

            @include screen-xlg() {
                height: 28px;
                font-size: 12px;
                border-radius: 2px;
            }
        }

        &-subtext {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            color: #bdbdbd;

            @include screen-xlg() {
                font-size: 12px;
            }
        }

        &-admin {
            max-width: 100px;
            font-size: 15px;
            line-height: 19px;
            color: #4f4f4f;
            cursor: pointer;

            &-not-allowed {
                cursor: not-allowed;
            }
        }
    }

    &__contacts {
        padding: 5px 0 5px 20px;
        border-left: 2px dashed #999;
        margin-left: 5px;

        &-title {
            font-weight: 600;
            font-size: 20px;
            //line-height: 26px;
            color: #58595b;
            margin: 0 0 5px 0;

            @include screen-xlg() {
                font-size: 14px;
            }
        }

        &-text {
            font-weight: 600;
            font-size: 16px;
            //line-height: 20px;
            color: #58595b;
            margin: 5px 0;

            @include screen-xlg() {
                font-size: 12px;
            }
        }

        &-subtext {
            height: 40px;
            font-size: 16px;
            line-height: 20px;
            color: #58595b;
            margin: 3px 0;
            padding: 10px 20px;
            background: #efefef;
            border-radius: 7px;
            width: 100%;
            outline: none !important;
            border: none;

            @include screen-xlg() {
                height: 28px;
                font-size: 12px;
            }

            &.-email {
                background: $color-new-gray-6;
            }

            &.-select {
                padding: 0 5px;

                input {
                    @include screen-xlg() {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }

        &-subtext-small {
            font-size: 16px;
            line-height: 20px;
            color: #58595b;
            margin: 3px 0;
            padding: 10px 20px;
            background: #efefef;
            border-radius: 7px;
            max-width: 120px;
            outline: none !important;
            border: none;
            height: 40px;

            @include screen-xlg() {
                height: 28px;
                font-size: 12px;
            }
        }

        &-suggestion-input {
            padding: 0;
            height: 40px;
            border-radius: 5px;

            @include screen-xlg() {
                height: 28px;
            }

            .AppInput__input-container-input {
                padding: 11px 15px;

                @include screen-xlg() {
                    padding: 7px 10px;
                }
            }

            input {
                padding: 12px;
                font-weight: 500;

                @include screen-xlg() {
                    font-size: 12px;
                }
            }

            span {
                border: none !important;
            }
        }

        &-icon {
            width: 22px;
            height: 22px;
            margin-right: 10px;

            @include screen-xlg() {
                width: 15px;
                height: 15px;
            }

            &.-invisible {
                margin-right: 15px;
            }
        }
    }

    &__action {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: 50px;

        @include screen-xlg() {
            margin-top: 0;
        }

        &-decline-btn {
            border: 1px solid #999;
            width: 260px;
            height: 50px;
            margin: 15px;
            border-radius: 7px;
            font-size: 21px;
            line-height: 21px;
            text-align: center;
            color: #58595b;
            cursor: pointer;

            @include screen-xlg() {
                width: 184px;
                height: 35px;
            }
        }

        &-join-btn {
            background: #006eb8;
            width: 260px;
            height: 50px;
            margin: 15px;
            border-radius: 7px;
            font-size: 21px;
            line-height: 21px;
            text-align: center;
            color: #fff;
            cursor: pointer;

            @include screen-xlg() {
                width: 184px;
                height: 35px;
            }
        }

        &-publish-btn {
            border: 1px solid #999;
            width: 150px;
            height: 50px;
            margin: 15px;
            border-radius: 7px;
            font-size: 21px;
            font-family: $font-family-body;
            line-height: 21px;
            text-align: center;
            color: #58595b;
            cursor: pointer;

            @include screen-xlg() {
                width: 106px;
                height: 35px;
                font-size: 14px;
            }

            &.-blue {
                background: $color-dark-blue;
                border-color: $color-dark-blue;
                color: $color-white;
            }
        }

        &-quit-btn {
            border: none;
            width: 250px;
            height: 50px;
            margin: 15px;
            border-radius: 7px;
            font-size: 21px;
            line-height: 21px;
            text-align: center;
            color: #58595b;
            cursor: pointer;
            display: flex;
            align-items: center;

            @include screen-xlg() {
                font-size: 14px;
            }
        }

        &-quit-icon {
            width: 22px;
            height: 22px;
            margin-right: 18px;

            @include screen-xlg() {
                width: 15px;
                height: 15px;
            }
        }
    }
}

.ReferralCode {
    &__menu {
        outline: none;
        background: #fff;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        overflow: hidden;
        height: 100vh;
        overflow-y: scroll;
        position: relative;

        &-back {
            position: absolute;
            left: 21px;
            cursor: pointer;
        }

        &-title {
            height: 40px;
            background: $color-grey1;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            text-align: center;
            color: #fff;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            @include screen-xlg() {
                height: 29px;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    &__content {
        margin: 0px 25px;
    }

    &__subtitle {
        font-size: 11px;
        text-align: center;
    }

    &__company {
        align-items: center;
        justify-content: center;

        &-image {
            height: 35px;
            width: 35px;
            border-radius: 50%;
        }
        &-name {
            font-weight: bold;
            margin-left: 3px;
        }
    }

    &__data {
        overflow-y: scroll;
        border-top: solid black 1px;

        &-title {
            width: 50%;
            font-weight: bold;
            padding: 10px 0px;
        }

        &-code {
            width: 50%;
        }

        &-users {
            &-image {
                align-items: center;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                display: inline-block;

                &-empty {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: #ebf7ff;
                    display: inline-block;
                }
            }

            &-details {
                width: 90%;
                border-bottom: solid black 1px;
                align-items: center;
                padding: 10px 0;

                &-name {
                    width: 60%;

                    &-empty {
                        color: $color-grey1;
                    }
                }

                &-date {
                    width: 40%;
                    text-align: right;
                }
            }
        }

        button {
            background-color: white;
            border-color: white;
            border-style: solid;
            color: $color-dark-blue;
            text-decoration: underline;
            cursor: pointer;
            float: right;
            padding: 10px 0;
            align-items: center;

            img {
                height: 20px;

                @include screen-xlg() {
                    height: 18px;
                }
            }
        }

        p {
            margin: 0px 5px;
        }
    }

    &__tooltip {
        position: absolute;
        border-radius: 10px;
        width: 150px;
        padding: 0 10px;
        right: -2vw;
        top: 14vh;
        transform: translateX(-50%);
        background: $color-grey1;
        font-weight: 400;
        font-size: 16px;
        font-family: $font-family-body;
        line-height: 1;
        z-index: 100;
        text-align: center;
        box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);

        @include screen-xlg() {
            width: 100px;
            font-size: 13px;
            left: auto;
            right: -1vw;
            top: 19vh;
        }
    }
}
