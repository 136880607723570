@import 'styles/_variables';
@import 'styles/_mixins';

#root,
.AppContainer {
    height: 100%;
    min-height: 100%;
}

.AppContainer {
    $root: &;

    &__container {
        height: 100%;
        min-height: 100%;

        #{$root}--withHeaderNav & {
            @include header-height('padding-top');
        }

        /* in order to have consistent layout in all modules */
        &-dashboard-layout {
            background-color: #ebf7ff;
            width: 100% !important;
            height: 100%;
            padding: 20px;
            display: flex;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    //
    // &--withSideNav {
    //     padding-left: $AppSideNav-width;
    // }
}
