@import 'styles/_variables';

.SafeImage {
    $root: &;

    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;

    &--isRound {
        border-radius: 50%;
    }

    &--isSquareRound {
        border-radius: 5px;
    }

    &__main {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: inherit;
        height: inherit;
        overflow: hidden;
        font-size: 16px;
        color: $color-lightgray;
        object-fit: cover;
        border-radius: inherit;

        /* to prevent image from shrinking so that it appears full circle not ellipse */
        flex-shrink: 0;



        &-img {
            width: 100%;
            height: 100%;
            object-fit: inherit;
            overflow: hidden;
            flex-shrink: 0;
        }


        &-text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: $color-lightgray;
            font-size: inherit;
            font-weight: $font-bold;
            color: white;
        }
    }

    &__sub {
        position: absolute;
        top: 95%;
        left: 95%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        transform: translate(-60%, -80%);
        background-color: inherit;
    }
}
